import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "refetching"
    }}>{`Refetching`}</h1>
    <p>{`You can use the `}<inlineCode parentName="p">{`refetch`}</inlineCode>{` function provided by the hook to trigger another request at any time.`}</p>
    <p>{`Common use cases:`}</p>
    <ul>
      <li parentName="ul">{`Allow the user to retry a failed request`}</li>
      <li parentName="ul">{`Provide the user with a way to refresh data on-demand`}</li>
      <li parentName="ul">{`Periodically request new data to keep UI up-to-date`}</li>
    </ul>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { useAxios } from 'use-axios-client';

export default () => {
  const { error, refetch } = useAxios({
    url: 'https://example/api',
  });

  if (error) {
    return <Button onClick={() => refetch()}>Retry</Button>;
  }
};
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      